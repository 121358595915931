import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import { graphql } from "gatsby"

const GetStartedPage = (props) => {
  var pageContext = props.pageContext
  var location = props.location
  var data = props.data
  var post
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allUniquePagesJson.nodes[0]
        break
      case "es":
        post = data.allSpanishUniquePagesJson.nodes[0]
        break
      default:
        post = data.allUniquePagesJson.nodes[0]
    }
  } else {
    post = data.allUniquePagesJson.nodes[0]
  }
  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"} language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div className="columns top-section text-section is-mobile white-back get-started">
                <div className="column is-hidden-mobile is-4-tablet"></div>
                <div className="column">
                  <div>
                    <h1>{post.heading}</h1>
                    <p className="large">{post.blurb}</p>
                  </div>
                </div>
                <div className="column is-hidden-mobile is-4-tablet"></div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div
                className="columns body-section text-section is-mobile form-area new-patient-form"
                style={{ paddingTop: "0" }}
              >
                <div className="column is-1-mobile is-5-tablet"></div>
                <div className="column">
                  <div>
                    <h5>New Patient Contact Form</h5>
                    <Seamless src="/getStartedForm.js" />
                  </div>
                </div>
                <div className="column is-1-mobile is-5-tablet"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query getStartedPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        blurb
      }
    }
  }
`

export default GetStartedPage
